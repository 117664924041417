.segment {
    border-left: 1px solid red;

}

.fab {
    position: absolute;
    top: 1rem;
    left: 1rem;
    font-size: 8px;
    @apply p-2 rounded-lg text-slate-300 transition-all;
}

.addLink {
    background: #313140;
    transition: 0.3s;
}

.addLink:hover {
    background: #434358;
}
/*.fab2 {*/
/*    position: absolute;*/
/*    top: 1rem;*/
/*    left: 12rem;*/
/*    font-size: 8px;*/
/*    @apply p-2 rounded-lg bg-slate-800 hover:bg-slate-700 text-slate-300 transition-all;*/
/*}*/

/***************************************************/
/***************************************************/
/***************************************************/

.react-icons {
  vertical-align: middle;
}


.cm-editor {
    /*border: 1px solid red;*/
    word-break: break-word;
    height:100%;
    font-size: 14px;
    padding: 1rem;
    border-radius: 8px;

}

.cm-content {
    /*[>border: 1px solid red;<]*/
    word-break: break-word;
    white-space: pre-wrap !important;
    /*[>overflow-x: hidden;<]*/
}

.CodeMirror-wrap pre {
    word-break: break-word;
}

.cke_reset_all .CodeMirror-scroll * {
    white-space: pre;
}

.cke_reset_all .CodeMirror-scroll * {
    white-space: pre-wrap;
}

.cm-gutterElement {
    display: none;
}
.cm-line {
    line-height: 2 !important;
}

.cm-activeLine {
    border-radius: 3px;

}

.ͼ11 {
    color: #8c76cf;
    font-weight: 900;
    /*font-size: 20px;*/
    /*@apply: font-black border-2 border-red-500;*/

}

.ͼ13 {
    color: #98c379 !important;
    font-stretch: ultra-expanded !important;
    /*font-size: 20px;*/
}
